<div  class="rtl" style="width: 750px">
    <div class="d-flex justify-content-between my-4">
      <h2 mat-dialog-title class="fw-bold">اضافة جديد</h2>
  
      <i-feather mat-dialog-close name="x" class="breadcrumb-icon"></i-feather>
    </div>
    <hr />
    <mat-dialog-content class="mat-typography">
      <form [formGroup]="form">
        <div  class="rtl">
          <div class="row">
            <div class="col-12">
              <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>  كلمة السر الحالية</mat-label>
                <input
                  matInput
                  formControlName="currentPass"
                  placeholder="كلمة السر الحالية"
                />
                <mat-error
                  *ngIf="form.get('currentPass').invalid && form.get('currentPass').touched"
                >
                    كلمة السر القديمة مطلوبة</mat-error
                >
              </mat-form-field>
            </div>
            
          </div>
  
          <div class="row">
            <div class="col-12">
              <mat-form-field class="example-full-width my-3" appearance="fill">
                <mat-label class="px-2">كلمة السر</mat-label>
                <input
                  matInput
                  [type]="hide ? 'password' : 'text'"
                  formControlName="newPass"
                  class="px-2"
                />
                <a
                  class="show-pwd-icon"
                  href="#"
                  onClick="return false;"
                  matSuffix
                  (click)="hide = !hide"
                  [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hide"
                >
                <i-feather name="eye-off" class="breadcrumb-icon" *ngIf="hide"></i-feather>
                <i-feather name="eye" class="breadcrumb-icon" *ngIf="!hide"></i-feather>
                </a>
                <mat-error *ngIf="form.get('newPass').hasError('required')"   class="text-danger rounded-2">
                  يجب ادخال كلمة السر الجديدة
                </mat-error>
              </mat-form-field>
            </div>


            <div class="col-12">
              <mat-form-field class="example-full-width my-3" appearance="fill">
                <mat-label class="px-2">تأكيد كلمة السر </mat-label>
                <input
                  matInput
                  [type]="hide ? 'password' : 'text'"
                  formControlName="confirmNewPass"
                  class="px-2"
                />
                <a
                  class="show-pwd-icon"
                  href="#"
                  onClick="return false;"
                  matSuffix
                  (click)="hide = !hide"
                  [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hide"
                >
                <i-feather name="eye-off" class="breadcrumb-icon" *ngIf="hide"></i-feather>
                <i-feather name="eye" class="breadcrumb-icon" *ngIf="!hide"></i-feather>
                </a>
                <mat-error *ngIf="form.get('confirmNewPass').hasError('required')"   class="text-danger rounded-2">
                  يجب  تأكيد ادخال كلمة السر الجديدة
                </mat-error>
              </mat-form-field>
            </div>


          </div>
        </div>
      </form>
    </mat-dialog-content>
  
    <mat-dialog-actions>
      <button
        mat-flat-button
        mat-dialog-close
        color="primary"
        class="btn btn-secondary"
      >
        اغلاق
      </button>
      <button mat-button color="primary" (click)="onSubmit()">تغيير</button>
    </mat-dialog-actions>
  </div>