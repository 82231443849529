import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { User } from "../models/user";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  private currentUserSubjectData: BehaviorSubject<User>;
  creatorUserId: number;

  public currentUser: Observable<User>;
  public currentUserData: Observable<User>;
  public userRole: any;
  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();

    this.currentUserSubjectData = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUserData"))
    );
    this.currentUserData = this.currentUserSubjectData.asObservable();
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.creatorUserId = currentUser ? currentUser.Id : null;
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
  public get currentUserDataValue(): User {
    return this.currentUserSubjectData.value;
  }
  login(username: string, password: string): Observable<any> {
    return this.http
      .post<any>(`${environment.backApiUrl}/Accounts/Login`, {
        uname: username,
        upass: password,
      })
      .pipe(
        map((response: any) => {
          if (response.state && response.data.length>0) {
          
            if (response.data[0].role == "2") {
              response.data[0].role = "Admin";
            }
            localStorage.setItem("currentUser", JSON.stringify(response.data[0]));
            this.currentUserSubject.next(response.data[0]);
            const newDoctorId = response.data[0].Column1;
            return true;
          } else {
            console.error("Error:", response.errorMessage);
            return false;
          }
        })
       
      );
  }
  logout() {
    return this.http
    .get(
      `${environment.backApiUrl}/Accounts/logOut/${this.creatorUserId}`
    )
    .pipe(
      map((response: any) => {
        if (response.state) {
          return response.data;
        } else {
          console.error("Error:", response.errorMessage);
          return false;
        }
      })
    );
  }
  changePawword(form: any) {
    const requestBody = {
      UserId: this.creatorUserId,
      OldPassword: form.currentPass,
      NewPassword: form.newPass,
    };
    return this.http
      .put(
        `${environment.backApiUrl}/Accounts/ChangePassword`,
        requestBody // Pass the dynamically constructed request body
      )
      .pipe(
        map((response: any) => {
          if (response.state) {
            return true;
          } else {
            console.error("Error:", response.errorMessage);
            return false;
          }
        })
      );
  }
  Deleteusers(id: number) {
    return this.http
      .delete(
        `${environment.backApiUrl}/Accounts/DeleteUser/${id}`
      )
      .pipe(
        map((response: any) => {
          if (response.state) {
            return response.data;
          } else {
            console.error("Error:", response.errorMessage);
            return false;
          }
        })
      );
  }
  saveNewUser(user: any) {
   

    return this.http
      .post(
        `${environment.backApiUrl}/Accounts/AddNewUser`,
        user // Pass the dynamically constructed request body
      )
      .pipe(
        map((response: any) => {
          if (response.state) {
            return response.data;
          } else {
            console.error("Error:", response.errorMessage);
            return false;
          }
        })
      );
  }

  saveNewUserOrg(org: any) {
    return this.http
      .post(
        `${environment.backApiUrl}/Accounts/AddNewUserOrg`,
        org // Pass the dynamically constructed request body
      )
      .pipe(
        map((response: any) => {
          if (response.state) {
            return response.data;
          } else {
            console.error("Error:", response.errorMessage);
            return false;
          }
        })
      );
  }
  saveNewUserRoles(user: any) {
   

    return this.http
      .post(
        `${environment.backApiUrl}/Accounts/AddNewUserRole`,
        user // Pass the dynamically constructed request body
      )
      .pipe(
        map((response: any) => {
          if (response.state) {
            return response.data;
          } else {
            console.error("Error:", response.errorMessage);
            return false;
          }
        })
      );
  }

  addNewPermisions(permisions:any){
    return this.http
    .post(
      `${environment.backApiUrl}/Accounts/addNewUserPermisions`,
      permisions // Pass the dynamically constructed request body
    )
    .pipe(
      map((response: any) => {
        if (response.state) {
          return response.data;
        } else {
          console.error("Error:", response.errorMessage);
          return false;
        }
      })
    );
  }
  GetSpecificUser(id:number){
    return this.http
    .get(
      `${environment.backApiUrl}/Accounts/GetSpecificUser/${id}`
    )
    .pipe(
      map((response: any) => {
        if (response.state) {
          return response.data;
        } else {
          console.error("Error:", response.errorMessage);
          return false;
        }
      })
    );
  }
  getSpecificUserRoles(id: number): Observable<any> {
    return this.http
      .get(`${environment.backApiUrl}/Accounts/GetSpecificUserRoles/${id}`)
      .pipe(
        map((response: any) => {
          if (response.state) {
            return response.data;
          } else {
            console.error("Error:", response.errorMessage);
            return false;
          }
        })
      );
  }
  getSpecificUserOrgs(id: number): Observable<any> {
    return this.http
      .get(`${environment.backApiUrl}/Accounts/GetSpecificUserOrg/${id}`)
      .pipe(
        map((response: any) => {
          if (response.state) {
            return response.data;
          } else {
            console.error("Error:", response.errorMessage);
            return false;
          }
        })
      );
  }
  getSpecificUserPermisions(id: number): Observable<any> {
    return this.http
      .get(`${environment.backApiUrl}/Accounts/GetSpecificUserPermisions/${id}`)
      .pipe(
        map((response: any) => {
          if (response.state) {
            return response.data;
          } else {
            console.error("Error:", response.errorMessage);
            return false;
          }
        })
      );
  }
  
  SetLockedOut(id: number) {
    
    return this.http
      .get(`${environment.backApiUrl}/Accounts/SetLockedout/${id}`)
      .pipe(
        map((response: any) => {
          if (response.state) {
            return response.data;
          } else {
            console.error("Error:", response.errorMessage);
            return false;
          }
        })
      );
  }
  filterUsersOnPermision(permissionIds: number[]): Observable<any> {
    let params = new HttpParams();
    permissionIds.forEach(id => {
      params = params.append('permissionIds', id.toString());
    });
    return this.http.get(`${environment.backApiUrl}/Accounts/FiltreUserOnPermision`, { params: params }).pipe(
      map((response: any) => {
        if (response.state) {
          return response.data;
        } else {
          console.error("Error:", response.errorMessage);
          return false;
        }
      })
    );
  }
  DeleteAllUserPermisions(id: number) {
    return this.http
      .delete(
        `${environment.backApiUrl}/Accounts/DeleteUserPermisions/${id}`
      )
      .pipe(
        map((response: any) => {
          if (response.state) {
            return response.data;
          } else {
            console.error("Error:", response.errorMessage);
            return false;
          }
        })
      );
  }

  DeleteAllUserRoles(id: number) {
    return this.http
      .delete(
        `${environment.backApiUrl}/Accounts/DeleteUserRoles/${id}`
      )
      .pipe(
        map((response: any) => {
          if (response.state) {
            return response.data;
          } else {
            console.error("Error:", response.errorMessage);
            return false;
          }
        })
      );
  }


  DeleteAllUserOrgs(id: number) {
    return this.http
      .delete(
        `${environment.backApiUrl}/Accounts/DeleteUserOrgs/${id}`
      )
      .pipe(
        map((response: any) => {
          if (response.state) {
            return response.data;
          } else {
            console.error("Error:", response.errorMessage);
            return false;
          }
        })
      );
  }
  UpdateUserData(user:any){
    return this.http
    .put(
      `${environment.backApiUrl}/Accounts/UpdateUser`,
      user // Pass the dynamically constructed request body
    )
    .pipe(
      map((response: any) => {
        if (response.state) {
          return response.data;
        } else {
          console.error("Error:", response.errorMessage);
          return false;
        }
      })
    );

  }
}
