<nav #navbar class="navbar active">
  <div class="container-fluid rtl">
    <div class="navbar-header">
      <a
        href="#"
        onClick="return false;"
        class="navbar-toggle collapsed"
        (click)="isNavbarCollapsed = !isNavbarCollapsed"
        aria-expanded="false"
      ></a>
      <a
        href="#"
        onClick="return false;"
        class="bars rtl"
        (click)="mobileMenuSidebarOpen($event, 'overlay-open')"
      ></a>
      <a
        class="navbar-brand d-flex align-items-center justify-content-center h-50"
        routerLink="{{ homePage }}"
      >
        <img src="assets/Images/logo.PNG" width="50" alt="" />
        <span class="">lotus_collection</span>
      </a>
    </div>
    <div
      class="collapse navbar-collapse"
      [ngClass]="isNavbarCollapsed ? '' : 'show'"
    >
      <ul class="float-start collapse-menu-icon">
        <li>
          <button
            mat-button
            (click)="callSidemenuCollapse()"
            class="sidemenu-collapse nav-notification-icons"
          >
            <i-feather
              name="menu"
              class="material-icons-outlined icon-color"
            ></i-feather>
          </button>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <li class="nav-item user_profile">
          <i-feather name="bell" class="breadcrumb-icon"></i-feather>

          <button mat-button [matMenuTriggerFor]="profilemenu">
            <div ngbDropdownToggle>
              <span>{{ empName }}</span>
              <img
                src="https://img.freepik.com/free-vector/isolated-young-handsome-man-different-poses-white-background-illustration_632498-859.jpg?t=st=1711894075~exp=1711897675~hmac=12d48101179f3ddb7ae9a27f707372528929eefce7f79d08d4555872f61ba051&w=740"
                class="user_img"
                width="32"
                height="32"
                alt="User"
              />
            </div>
          </button>
          <mat-menu #profilemenu="matMenu" class="profile-menu rtl">
            <div class="noti-list">
              <div class="menu">
                <div class="user_dw_menu">
                  <button
                    mat-menu-item
                    class="bg-danger p-4"
                    style="
                      background-image: url(https://img.freepik.com/free-vector/blue-background-with-dots-lines_23-2147868434.jpg?w=740);
                      background-position: center;
                      background-size: cover;
                    "
                  >
                    <img
                      src="https://img.freepik.com/free-vector/isolated-young-handsome-man-different-poses-white-background-illustration_632498-859.jpg?t=st=1711894075~exp=1711897675~hmac=12d48101179f3ddb7ae9a27f707372528929eefce7f79d08d4555872f61ba051&w=740"
                      class="user_img rounded-circle"
                      width="50"
                      height="50"
                      alt="User"
                    />
                    <span class="text-light">
                      {{ userName }}
                    </span>
                  </button>

                  <button mat-menu-item>
                    <i-feather name="user" class="breadcrumb-icon"></i-feather>

                    <span style="font-family: system-ui;"> ادارة الحساب </span>
                  </button>
                  <hr style="color: #80808066; margin: 0" />

                  <button mat-menu-item>
                    <i-feather
                      name="more-horizontal"
                      class="breadcrumb-icon"
                    ></i-feather>
                    <span style="font-family: system-ui;" (click)="openDialog()"> تغيير كلمة المرور </span>
                  </button>

                  <hr style="color: #80808066; margin: 0" />

                  <button mat-menu-item>
                    <i-feather name="key" class="breadcrumb-icon"></i-feather>
                    <span style="font-family: system-ui;"> محاولات الدخول </span>
                  </button>
                  <hr style="color: #80808066; margin: 0" />

                  <button mat-menu-item>
                    <i-feather name="edit" class="breadcrumb-icon"></i-feather>
                    <span style="font-family: system-ui;"> تغيير الصورة الشخصية </span>
                  </button>

                  <hr style="color: #80808066; margin: 0" />

                  <button mat-menu-item>
                    <i-feather name="tool" class="breadcrumb-icon"></i-feather>
                    <span style="font-family: system-ui;"> اعداداتي </span>
                  </button>

                  <hr style="color: #80808066; margin: 0" />

                  <button mat-menu-item>
                    <i-feather name="eye" class="breadcrumb-icon"></i-feather>
                    <span style="font-family: system-ui;"> اعدادات بصرية </span>
                  </button>
                  <hr style="color: #80808066; margin: 0" />

                  <button mat-menu-item>
                    <i-feather
                      name="download"
                      class="breadcrumb-icon"
                    ></i-feather>
                    <span style="font-family: system-ui;"> تحميل البيانات اللي تم جمعها </span>
                  </button>
                  <hr style="color: #80808066; margin: 0" />
                  <button
                    (click)="logout()"
                    class="btn my-3 mx-4"
                    style="background-color: #5d78ff; color: white"
                  >
                    تسجيل خروج
                  </button>
                </div>
              </div>
            </div>
          </mat-menu>
          <i-feather name="message-square" class="breadcrumb-icon"></i-feather>
        </li>
        <!-- #END# Tasks -->
      </ul>
    </div>
  </div>
</nav>
