import { RouteInfo } from "./sidebar.metadata";
export const ROUTES: RouteInfo[] = [
  {
    path: "",
    title: "ادارة البيانات ",
    iconType: "material-icons-two-tone",
    icon: "table",
    class: "menu-toggle rtl",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [
      {
        path: "/manage-data/recieving-managemnt",
        title: "ادارة الأستلام",
        iconType: "material-icons-two-tone",
        icon: "more-vertical",
        class: "rtl",
        groupTitle: false,
        badge: "New",
        badgeClass: "badge bg-blue sidebar-badge float-right",
        role: [""],
        submenu: [],
      },
      {
        path: "/manage-data/export-management",
        title: "ادارة التصدير",
        iconType: "material-icons-two-tone",
        icon: "more-vertical",
        class: "",
        groupTitle: false,
        badge: "New",
        badgeClass: "badge bg-blue sidebar-badge float-right",
        role: ["Admin", "Doctor"],
        submenu: [],
      },
      {
        path: "/manage-data/collecting-management",
        title: "ادارة التحصيل",
        iconType: "material-icons-two-tone",
        icon: "more-vertical",
        class: "",
        groupTitle: false,
        badge: "New",
        badgeClass: "badge bg-blue sidebar-badge float-right",
        role: ["Admin", "Doctor"],
        submenu: [],
      },
      {
        path: "/manage-data/financial-managemnt",
        title: "الادارة المالية",
        iconType: "material-icons-two-tone",
        icon: "more-vertical",
        class: "",
        groupTitle: false,
        badge: "New",
        badgeClass: "badge bg-blue sidebar-badge float-right",
        role: ["Admin", "Doctor"],
        submenu: [],
      },
      {
        path: "/manage-data/type-of-service-provider",
        title: "نوع مقدم الخدمة",
        iconType: "material-icons-two-tone",
        icon: "more-vertical",
        class: "",
        groupTitle: false,
        badge: "New",
        badgeClass: "badge bg-blue sidebar-badge float-right",
        role: ["Admin", "Doctor"],
        submenu: [],
      },
      {
        path: "/manage-data/service-provider",
        title: "مقدمي الخدمة",
        iconType: "material-icons-two-tone",
        icon: "more-vertical",
        class: "",
        groupTitle: false,
        badge: "New",
        badgeClass: "badge bg-blue sidebar-badge float-right",
        role: ["Admin", "Doctor"],
        submenu: [],
      },
      {
        path: "/manage-data/type-of-care-companies",
        title: "نوع شركة الرعاية",
        iconType: "material-icons-two-tone",
        icon: "more-vertical",
        class: "",
        groupTitle: false,
        badge: "New",
        badgeClass: "badge bg-blue sidebar-badge float-right",
        role: ["Admin", "Doctor"],
        submenu: [],
      },
      {
        path: "/manage-data/care-companies",
        title: "شركات الرعاية",
        iconType: "material-icons-two-tone",
        icon: "more-vertical",
        class: "",
        groupTitle: false,
        badge: "New",
        badgeClass: "badge bg-blue sidebar-badge float-right",
        role: ["Admin", "Doctor"],
        submenu: [],
      },
      {
        path: "/manage-data/shiping-companies",
        title: "شركات الشحن",
        iconType: "material-icons-two-tone",
        icon: "more-vertical",
        class: "",
        groupTitle: false,
        badge: "New",
        badgeClass: "badge bg-blue sidebar-badge float-right",
        role: ["Admin", "Doctor"],
        submenu: [],
      },
      {
        path: "/manage-data/delegats",
        title: "المندوبين",
        iconType: "material-icons-two-tone",
        icon: "more-vertical",
        class: "",
        groupTitle: false,
        badge: "New",
        badgeClass: "badge bg-blue sidebar-badge float-right",
        role: ["Admin", "Doctor"],
        submenu: [],
      },
      {
        path: "/manage-data/medical-network",
        title: "الشبكة الطبية",
        iconType: "material-icons-two-tone",
        icon: "more-vertical",
        class: "",
        groupTitle: false,
        badge: "New",
        badgeClass: "badge bg-blue sidebar-badge float-right",
        role: ["Admin", "Doctor"],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "ادارة النظام",
    iconType: "material-icons-two-tone",
    icon: "table",
    class: "menu-toggle rtl",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin","DM"],
    submenu: [
      {
        path: "/manage-system/organization-unit",
        title: "الوحدات التنظيمية",
        iconType: "material-icons-two-tone",
        icon: "git-merge",
        class: "",
        groupTitle: false,
        badge: "New",
        badgeClass: "badge bg-blue sidebar-badge float-right",
        role: ["Admin", "Doctor"],
        submenu: [],
      },
      {
        path: "/manage-system/roles",
        title: "الأدوار",
        iconType: "material-icons-two-tone",
        icon: "folder",
        class: "",
        groupTitle: false,
        badge: "New",
        badgeClass: "badge bg-blue sidebar-badge float-right",
        role: ["Admin", "Doctor"],
        submenu: [],
      },
      {
        path: "/manage-system/users",
        title: "المستخدمين",
        iconType: "material-icons-two-tone",
        icon: "users",
        class: "",
        groupTitle: false,
        badge: "New",
        badgeClass: "badge bg-blue sidebar-badge float-right",
        role: ["Admin", "Doctor"],
        submenu: [],
      },
      {
        path: "/manage-system/languages",
        title: "اللغات",
        iconType: "material-icons-two-tone",
        icon: "layers",
        class: "",
        groupTitle: false,
        badge: "New",
        badgeClass: "badge bg-blue sidebar-badge float-right",
        role: ["Admin", "Doctor"],
        submenu: [],
      },
      {
        path: "/manage-system/audite-records",
        title: "سجلات التدقيق",
        iconType: "material-icons-two-tone",
        icon: "sliders",
        class: "",
        groupTitle: false,
        badge: "New",
        badgeClass: "badge bg-blue sidebar-badge float-right",
        role: ["Admin", "Doctor"],
        submenu: [],
      },
    ],
  },
];
