import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MedicalNetworkService } from "src/app/core/service/medical-network.service";
import { ServiceProviderTypesService } from "src/app/core/service/service-provider-types.service";
import { AuthService } from "src/app/core/service/auth.service";
@Component({
  selector: 'app-password-dialog',
  templateUrl: './password-dialog.component.html',
  styleUrls: ['./password-dialog.component.sass']
})
export class PasswordDialogComponent {
  form: FormGroup;
  hide = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<PasswordDialogComponent>,
    private fb: FormBuilder,
    private authServ:AuthService

  ) {}

  ngOnInit(): void {

    this.buildForm();
    
  }

  buildForm() {
    this.form = this.fb.group({
      currentPass: ["", Validators.required],
      newPass: ["", Validators.required],
      confirmNewPass: ["", Validators.required],
    });
  }
  onSubmit() {
    if (this.form.valid) {
      this.authServ.changePawword(this.form.value).subscribe((res) => {
        if (res) {
          this.dialogRef.close(res);
        }
      });
      console.log("Form submitted successfully:");
    } else {
      console.log("Form is invalid. Please check the fields.");
    }
  }
}
