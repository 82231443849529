import { Component, OnInit } from "@angular/core";
import { Event, Router, NavigationStart, NavigationEnd } from "@angular/router";
import { PlatformLocation } from "@angular/common";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  currentUrl: string;
  // Define a variable to store the retrieved item from local storage
  storedUser: string;

  constructor(
    public _router: Router,
    location: PlatformLocation
  ) {
    // Subscribe to storage events to monitor changes in localStorage
    window.addEventListener('storage', this.storageEventListener.bind(this));
    this._router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        this.currentUrl = routerEvent.url.substring(
          routerEvent.url.lastIndexOf("/") + 1
        );
      }
      if (routerEvent instanceof NavigationEnd) {
      }
      window.scrollTo(0, 0);
    });
  }

  ngOnInit() {
  }

  // Event listener for storage events
  storageEventListener(event: StorageEvent) {
    if (event.key === 'currentUser') {
      // Update storedUser when 'currentUser' is changed in localStorage
      this.storedUser = event.newValue;
    }
  }
}
