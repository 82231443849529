<div>
  <!-- Left Sidebar -->
  <aside id="leftsidebar" class="sidebar rtl">
    <div class="menu">
      <ng-scrollbar [style.height]="listMaxHeight + 'px'" visibility="hover">
        <ul class="list">
          <li>
            <a class="menu-top" routerLink="/admin/dashboard/main">
              <span class="hide-menu" 
                >الرئيسية
              </span>
              <i-feather name="home" class="breadcrumb-icon"></i-feather>
            </a>
          </li>
          <li>
            <a class="menu-toggle" (click)="callToggleMenu($event, 5)">
              <span class="hide-menu" 
                >ادارة البيانات
              </span>
              <i-feather name="table" class="breadcrumb-icon"></i-feather>
            </a>
            <ul class="ml-menu">
              <li>
                <a class="menu-top" routerLink="/manage-data/recieving-managemnt">
                  <span class="hide-menu" 
                    >
                    ادارة الأستلام
                  </span>
                  <i-feather name="more-vertical" class="breadcrumb-icon"></i-feather>
                </a>
              </li>
              <li>
                <a class="menu-top" routerLink="/manage-data/export-management">
                  <span class="hide-menu" 
                    >
                    ادارة التصدير
                  </span>
                  <i-feather name="more-vertical" class="breadcrumb-icon"></i-feather>
                </a>
              </li>
              <li>
                <a class="menu-top" routerLink="/manage-data/collecting-management">
                  <span class="hide-menu" 
                    >ادارة التحصيل
                  </span>
                  <i-feather name="more-vertical" class="breadcrumb-icon"></i-feather>
                </a>
              </li>
              <li>
                <a class="menu-top"  routerLink="/manage-data/financial-managemnt">
                  <span class="hide-menu"
                    >ادارة المالية
                  </span>
                  <i-feather name="more-vertical" class="breadcrumb-icon"></i-feather>
                </a>
              </li>
              <li>
                <a class="menu-top" routerLink="/manage-data/type-of-service-provider">
                  <span class="hide-menu" 
                    
                  >نوع مقدمي الخدمة


                  </span>
                  <i-feather name="more-vertical" class="breadcrumb-icon"></i-feather>
                </a>
              </li>
              <li>
                <a class="menu-top" routerLink="/manage-data/service-provider">
                  <span class="hide-menu" 
                    >مقدمي الخدمة
                  </span>
                  <i-feather name="more-vertical" class="breadcrumb-icon"></i-feather>
                </a>
              </li>
              <li>
                <a class="menu-top" routerLink="/manage-data/dues">
                  <span class="hide-menu" 
                    > المستحقات   
                  </span>
                  <i-feather name="more-vertical" class="breadcrumb-icon"></i-feather>
                </a>
              </li>
              <li>
                <a class="menu-top" routerLink="/manage-data/type-of-care-companies">
                  <span class="hide-menu" 
                    >نوع شركة الرعاية
                  </span>
                  <i-feather name="more-vertical" class="breadcrumb-icon"></i-feather>
                </a>
              </li>
              <li>
                <a class="menu-top" routerLink="/manage-data/care-companies">
                  <span class="hide-menu" 
                    >شركة الرعاية
                  </span>
                  <i-feather name="more-vertical" class="breadcrumb-icon"></i-feather>
                </a>
              </li>
              <li>
                <a class="menu-top" routerLink="/manage-data/shiping-companies">
                  <span class="hide-menu" 
                    >شركات الشحن
                  </span>
                  <i-feather name="more-vertical" class="breadcrumb-icon"></i-feather>
                </a>
              </li>
              <li>
                <a class="menu-top" routerLink="/manage-data/delegats">
                  <span class="hide-menu" 
                    >المندوبين
                  </span>
                  <i-feather name="more-vertical" class="breadcrumb-icon"></i-feather>
                </a>
              </li>
              <li>
                <a class="menu-top" routerLink="/manage-data/medical-network">
                  <span class="hide-menu" 
                    >الشبكة الطبية
                  </span>
                  <i-feather name="more-vertical" class="breadcrumb-icon"></i-feather>
                </a>
              </li>
              
            </ul>
          </li>


          <li>
            <a class="menu-toggle" (click)="callToggleMenu($event, 5)">
              <span class="hide-menu" 
                >ادارة النظام
              </span>
              <i-feather name="table" class="breadcrumb-icon"></i-feather>
            </a>
            <ul class="ml-menu">
              <li>
                <a class="menu-top" routerLink="/manage-system/organization-unit">
                  <span class="hide-menu" 
                    >
                     الوحدات التنظيمية
                  </span>
                  <i-feather name="git-merge" class="breadcrumb-icon"></i-feather>
                </a>
              </li>
              <li>
                <a class="menu-top" routerLink="/manage-system/roles">
                  <span class="hide-menu" 
                    >
                     الأدوار
                  </span>
                  <i-feather name="folder" class="breadcrumb-icon"></i-feather>
                </a>
              </li>
              <li>
                <a class="menu-top" routerLink="/manage-system/users">
                  <span class="hide-menu" 
                    > المستخدمين
                  </span>
                  <i-feather name="users" class="breadcrumb-icon"></i-feather>
                </a>
              </li>
              <li>
                <a class="menu-top"  routerLink="/manage-system/languages">
                  <span class="hide-menu"
                    > اللغات
                  </span>
                  <i-feather name="layers" class="breadcrumb-icon"></i-feather>
                </a>
              </li>
              <li>
                <a class="menu-top" routerLink="/manage-system/audite-records">
                  <span class="hide-menu" 
                    
                  >  تسجيلات التدقيق


                  </span>
                  <i-feather name="sliders" class="breadcrumb-icon"></i-feather>
                </a>
              </li>
         
              
            </ul>
          </li>
          <li>
            <a class="" class="menu-top" routerLink="/admin/dashboard/about">
              <span class="hide-menu" 
                >عن اللوتس للتحصيل</span
              >
              <i-feather
                name="more-vertical"
                class="breadcrumb-icon"
              ></i-feather>
            </a>
          </li>
        </ul>
      </ng-scrollbar>
    </div>
  </aside>
</div>
